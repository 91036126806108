<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5 ml-3">Operadores</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-3">
              <label for="name" class="block text-sm font-medium">Nome</label>
              <input
                v-model="form.nome"
                type="text"
                name="name"
                id="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="email" class="block text-sm font-medium">Email</label>
              <input
                v-model="form.email"
                type="text"
                name="email"
                id="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-2">
              <label for="phone" class="block text-sm font-medium">Telefone</label>
              <input
                v-model="form.telefone"
                type="number"
                name="phone"
                id="phone"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-3">
            <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
              <button
                @click="alterarPassword = !alterarPassword"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500"
              >
                Alterar password
              </button>
            </div>
          </div>
          <div v-if="alterarPassword" class="grid grid-cols-12 gap-6 mt-3">
            <div class="col-span-12 md:col-span-3">
              <label for="password" class="block text-sm font-medium">Senha</label>
              <input
                autocomplete="new-password"
                v-model="form.password"
                type="password"
                name="password"
                id="password"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-3">
              <label for="password2" class="block text-sm font-medium"
                >Confirme a senha</label
              >
              <input
                autocomplete="new-password"
                v-model="form.password2"
                type="password"
                name="password2"
                id="password2"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div
            class="grid grid-cols-12 gap-6 mt-3"
            v-if="$store.state.user.lzv2.centrocusto"
          >
            <div class="col-span-12 md:col-span-4">
              <label for="centrocusto" class="block text-sm font-medium"
                >Centro de Custo</label
              >
              <select
                v-model="form.centrocusto"
                required
                name="centrocusto"
                id="centrocusto"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
                <option :value="null"> Nenhum </option>
                <option v-for="c in centroscusto" :key="c._id" :value="c._id">
                  {{ c.nome }}
                </option>
              </select>
            </div>
          </div>
          <hr class="mt-6 mb-3" />
          <h3 class="font-semibold text-lg mb-3">Acessos operador</h3>
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-12" v-if="!$store.state.user.centrocusto">
              <label for="admin" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  @change="setAdmin"
                  v-model="form.admin"
                  class="rounded-sm"
                  id="admin"
                />
                <span class="ml-2">
                  Admin {{ form.centrocusto ? '(visualização de todo o sistema vinculado ao centro de custo)' : '(visualização de todo o sistema e a todos os centros de custo)' }}
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''" v-if="$store.state.user.lzv2.respostas">
              <label for="chatAcesso" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin"
                  @change="setchatAcesso"
                  v-model="form.chatAcesso"
                  class="rounded-sm"
                  id="chatAcesso"
                />
                <span class="ml-2">
                  Acesso ao chat (Permite receber e enviar mensagens)
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''" v-if="$store.state.user.lzv2.respostas"> 
              <label for="visualizarFiltrosChat" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin"
                  @input="() => form.visualizarFiltrosChat = !form.visualizarFiltrosChat"
                  v-model="form.visualizarFiltrosChat"
                  class="rounded-sm"
                  id="visualizarFiltrosChat"
                />
                <span class="ml-2">
                  Acesso aos filtros do Chat (centro de custo e operador)
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" v-if="$store.state.user.lzv2.respostas">
              <label for="visualizarChat" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  @change="setvisualizarChat"
                  v-model="form.visualizarChat"
                  class="rounded-sm"
                  id="visualizarChat"
                />
                <span class="ml-2">
                  Apenas Visualizar Chat <small class="text-sm text-red-800">Supervisor Operadores</small> {{ !form.admin || form.centrocusto ? '(Limitado ao seu centro de custo)' : '' }}
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''">
              <label for="uploadCampanha" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin"
                  v-model="form.uploadCampanha"
                  class="rounded-sm"
                  id="uploadCampanha"
                />
                <span class="ml-2">
                  Permitir upload de Campanha {{ !form.admin || form.centrocusto ? '(Limitado ao seu centro de custo)' : '' }}
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''">
              <label for="rastrearAcesso" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin ? true : false"
                  v-model="form.rastrearAcesso"
                  class="rounded-sm"
                  id="rastrearAcesso"
                />
                <span class="ml-2">
                  Permitir rastrear envios {{ !form.admin || form.centrocusto ? '(Limitado ao seu centro de custo)' : '' }}
                </span>
              </label>
            </div>
            <div class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''">
              <label for="relatorioAcesso" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin ? true : false"
                  v-model="form.relatorioAcesso"
                  class="rounded-sm"
                  id="relatorioAcesso"
                />
                <span class="ml-2">
                  Permitir acesso a relatórios {{ !form.admin || form.centrocusto ? '(Limitado ao seu centro de custo)' : '' }}
                </span>
              </label>
            </div>
            <div v-if="$store.state.user.lzv2.validacao" class="col-span-12 md:col-span-12" :class="form.admin ? 'opacity-50' : ''">
              <label for="validacaoAcesso" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  :disabled="form.admin ? true : false"
                  v-model="form.validacaoAcesso"
                  class="rounded-sm"
                  id="validacaoAcesso"
                />
                <span class="ml-2">
                  Acesso a validação whatsapp (Permite fazer upload de campanhas de validação)
                </span>
              </label>
            </div>
            <div v-if="!$store.state.user.operador" class="col-span-12 md:col-span-12">
              <label for="configuracaoAcesso" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  v-model="form.configuracaoAcesso"
                  class="rounded-sm"
                  id="configuracaoAcesso"
                />
                <span class="ml-2">
                  Acesso a tela de configuração da conta
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3 text-left sm:px-6">
            <router-link
              :to="`/operadores`"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Voltar
            </router-link>
          </div>
          <div class="px-5 py-3 text-right sm:px-6">
            <button
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [
        { url: "/operadores", value: "Operadores" },
        { url: "/operadores/form", value: "Novo / Editar" },
      ],
      alterarPassword: this.$route.params.id ? false : true,
      centroscusto: [],
      form: {
        nome: "",
        email: "",
        password: "",
        password2: "",
        uploadCampanha: false,
        validacaoAcesso: false,
        relatorioAcesso: false,
        rastrearAcesso: false,
        visualizarFiltrosChat: false,
        configuracaoAcesso: false,
        admin: false,
        visualizarChat: false,
        chatAcesso: true, 
        centrocusto: null,
      },
    };
  },

  methods: {
    async save() {
      if(!this.$store.state.user.lzv2.respostas){
        this.form.chatAcesso = false;
        this.form.visualizarChat = false;
      }

      if(!this.$store.state.user.lzv2.validacao){
        this.form.validacaoAcesso = false;
      }

      if (!this.form.email) {
        return this.$vToastify.error("Digite o email");
      }
      if (!this.form.centrocusto && !this.form.admin) {
        return this.$vToastify.error("Digite o centro de custo");
      }
      if (this.alterarPassword && !this.form.password) {
        return this.$vToastify.error("Digite a senha");
      }
      if (this.alterarPassword && this.form.password !== this.form.password2) {
        return this.$vToastify.error("Senhas não coincidem");
      }

      if(!this.form.uploadCampanha && !this.form.chatAcesso && !this.form.visualizarChat) {
        return this.$vToastify.error("Upload e acesso ou visualização ao chat não podem ser falsos ao mesmo tempo");
      }

      const method = this.form._id ? "put" : "post";
      const req = await this.$http[method](`/v1/operador`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/operadores` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    setAdmin() {
      if(this.form.admin) {
        this.form.uploadCampanha = true;
        this.form.relatorioAcesso = true;
        this.form.visualizarChat = true;
        this.form.rastrearAcesso = true;
        this.form.visualizarFiltrosChat = true;
        this.form.chatAcesso = false;
        if(!this.$store.state.user.operador && !this.$store.state.user.lzv2.cliente_novavida) {
          this.form.configuracaoAcesso = true;
        }
        if(this.$store.state.user.lzv2.validacao) {
          this.form.validacaoAcesso = true;
        }
      }
    },
    setchatAcesso() {
      this.form.visualizarChat = false;
    },
    setvisualizarChat() {
      this.form.chatAcesso = false;
    },
  },
  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      const req = await this.$http.get(`/v1/operador/${id}`);
      this.form = req.data;
    }

    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;
  },
};
</script>
